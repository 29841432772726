import React from 'react';
import { Stack, StackProps, styled } from '@mui/material';
import { AspectRatios } from '@lib/enums/apectRatios';
import { MaybeResponsive } from '@lib/types/component';
import ResponsiveImg, { ResponsiveImageProps } from '@app/common/components/ResponsiveImg';
import { grey5 } from './bedrock/SwColors';
import { SwPaper } from './bedrock/SwPaper';
import { colors } from './bedrock/fundations';

export type SwFeatureImageProps = StackProps & {
    srcSet: ResponsiveImageProps['srcSet'];
    alt: string;
    aspectRatioImage?: MaybeResponsive<string | AspectRatios>;
    loading: JSX.IntrinsicElements['img']['loading'];
    maxHeight?: string;
    mainContentWidth?: number | string;
    mainContent: React.ReactNode;
    secondaryContent?: React.ReactNode;
};

const Image = styled(ResponsiveImg)<{ $bgcolor: string; $aspectRatioImage: MaybeResponsive<string | AspectRatios> }>`
    overflow: hidden;
    border: 1px solid ${grey5};
    background-color: ${($props) => $props.$bgcolor};
    border-radius: 8px;
    aspect-ratio: ${({ $aspectRatioImage }) =>
        typeof $aspectRatioImage === 'object' ? $aspectRatioImage.lg : $aspectRatioImage};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        aspect-ratio: ${({ $aspectRatioImage }) => (typeof $aspectRatioImage === 'object' ? $aspectRatioImage.md : 1)};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        aspect-ratio: ${({ $aspectRatioImage }) =>
            typeof $aspectRatioImage === 'object' ? $aspectRatioImage.sm : AspectRatios.portrait};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
        aspect-ratio: ${({ $aspectRatioImage }) =>
            typeof $aspectRatioImage === 'object' ? $aspectRatioImage.xs : AspectRatios.portrait};
    }
`;

export const SwFeatureImage: React.FC<SwFeatureImageProps> = (props) => {
    const {
        alt,
        srcSet,
        loading,
        mainContent,
        secondaryContent,
        mainContentWidth = 'auto',
        bgcolor = colors.sky[400],
        aspectRatioImage = AspectRatios.widescreen,
        ...rest
    } = props;

    return (
        <Stack sx={{ position: 'relative' }} {...rest}>
            <Stack sx={{ position: 'relative' }}>
                <SwPaper
                    bgcolor={colors.surface.subdued}
                    p={1}
                    sx={({ breakpoints }) => ({
                        position: 'absolute',
                        width: mainContentWidth,
                        [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
                            position: 'relative',
                            width: '100%',
                            transform: 'translate(0, 32px)',
                            marginTop: '-32px'
                        }
                    })}
                    width={mainContentWidth}
                >
                    <SwPaper p={3}>{mainContent}</SwPaper>
                </SwPaper>
                <Image
                    $aspectRatioImage={aspectRatioImage}
                    $bgcolor={bgcolor as string}
                    alt={alt}
                    loading={loading}
                    srcSet={srcSet}
                />
            </Stack>
            {secondaryContent ? (
                <SwPaper
                    bgcolor={colors.surface.subdued}
                    p={1}
                    sx={{
                        width: '100%',
                        transform: 'translate(0, -32px)',
                        marginBottom: '-32px'
                    }}
                    width={mainContentWidth}
                >
                    {secondaryContent}
                </SwPaper>
            ) : null}
        </Stack>
    );
};
