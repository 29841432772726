import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import { SwFeatureImage } from '@lib/components';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { grey3, grey5 } from '@lib/components/bedrock/SwColors';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { CDNClient } from '@lib/helpers/image';
import Seo from '@app/common/components/Seo';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import SwContainer from '../../components/v1/SwContainer';
import SwLayout from '../../components/v1/SwLayout';
import SwTopNav from '../../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../../components/v1/website/SwWebsiteFooter';

const imgSrc = '/journey/empty_island.png';

const Custom404 = ({ data }) => {
    const theme = useTheme();
    const isTabletOrAbove = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <SwLayout>
            <SwTopNav
                activePage={''}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />

            <WebsiteSectionsLayout hasLineAtTheEnd={false}>
                <SwContainer withMaxWidth={true}>
                    <SwFeatureImage
                        alt={'Empty island'}
                        loading={'eager'}
                        mainContent={
                            <>
                                <SwTypography component={'h1'} variant={'h3'}>
                                    Page not found
                                </SwTypography>
                                <SwTypography color={grey3} variant={'body1'}>
                                    Looks like we lost track of this page. Use your browser’s back button or find
                                    something new below.
                                </SwTypography>
                            </>
                        }
                        maxHeight={'684px'}
                        pt={2}
                        srcSet={{
                            xs: {
                                src: CDNClient.buildURL(imgSrc, {
                                    auto: 'format',
                                    h: 1400,
                                    fit: 'crop',
                                    ar: '0.75',
                                    crop: 'focalpoint',
                                    'fp-z': 1.5
                                }),
                                height: 468,
                                width: 468 * 0.75
                            },
                            sm: {
                                src: CDNClient.buildURL(imgSrc, {
                                    auto: 'format',
                                    h: 1400,
                                    fit: 'crop',
                                    crop: 'focalpoint',
                                    ar: '1',
                                    'fp-z': 1.1
                                }),
                                height: 792,
                                width: 792
                            },
                            md: {
                                src: CDNClient.buildURL(imgSrc, { auto: 'format', h: 1600, ar: '1.7778' }),
                                height: 684,
                                width: 684 * (16 / 9)
                            }
                        }}
                    />
                </SwContainer>
                <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={theme.spacing(2)}>
                    <SwContainer withMaxWidth={true}>
                        <SwTypography bold={true} color={grey3} component={'h2'} uppercase={true} variant={'caption'}>
                            The latest sweep updates
                        </SwTypography>
                    </SwContainer>
                    <SwContainer noGutter={isTabletOrAbove} withMaxWidth={true}>
                        <SwLatestContent
                            content={data.latestArticles.nodes}
                            renderItems={(article) => (
                                <SwRecordCard
                                    baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                    key={article.id}
                                    record={article}
                                    titleHeading={'h3'}
                                    width={{ xs: 300, md: 'auto' }}
                                />
                            )}
                        />
                    </SwContainer>
                    <SwContainer withMaxWidth={true}>
                        <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={theme.spacing(2)} width={'100%'}>
                            <SwLine color={grey5} direction={'horizontal'} spacing={0} />
                            <SwInternalLink to={'/blog'} variant={'secondary'}>
                                More news from Sweep <ArrowRight />
                            </SwInternalLink>
                        </SwFlexBox>
                    </SwContainer>
                </SwFlexBox>
            </WebsiteSectionsLayout>

            <SwWebsiteFooter newsletterForm={data.newsletterForm} />
        </SwLayout>
    );
};

export const Head = () => (
    <Seo title={'Not Found'}>
        <link
            as={'image'}
            href={CDNClient.buildURL(imgSrc, { auto: 'format', h: 1600, ar: '1.7778' })}
            media={'(min-width: 960px)'}
            rel={'preload'}
        />
        <link
            as={'image'}
            href={CDNClient.buildURL(imgSrc, {
                auto: 'format',
                h: 1400,
                fit: 'crop',
                ar: '0.75',
                crop: 'focalpoint',
                'fp-z': 1.5
            })}
            media={'(max-width: 599px)'}
            rel={'preload'}
        />
        <link
            as={'image'}
            href={CDNClient.buildURL(imgSrc, {
                auto: 'format',
                h: 1400,
                fit: 'crop',
                crop: 'focalpoint',
                ar: '1',
                'fp-z': 1.1
            })}
            media={'(min-width: 600px) and (max-width: 959px)'}
            rel={'preload'}
        />
    </Seo>
);

export const query = graphql`
    {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        latestArticles: allGraphCmsArticle(
            filter: { locale: { eq: en }, industry: { id: { eq: null } }, seo: { noIndex: { ne: true } } }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
    }
`;

export default Custom404;
